import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import BurskGoatfeature from '../../images/goats/burskaya_poroda_koz/feature-burskie-kozy.jpg'
import BurskGoatGallery1 from '../../images/goats/burskaya_poroda_koz/burskie-kozel-v-pole.jpg'
import BurskGoatGallery2 from '../../images/goats/burskaya_poroda_koz/burskie-kozy-3.jpg'
import BurskGoatGallery3 from '../../images/goats/burskaya_poroda_koz/burskiy-kozel.jpg'
import BurskGoatGallery4 from '../../images/goats/burskaya_poroda_koz/burskiy-kozel-v-zagone.jpg'


const BurskaiaPoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание Бурскаой породы коз', url:'#', status:true}]

    const indicators = [
      {name: 'Надой за 305 дней(л)', progress: 150, benchmark:1200},
      {name: 'Надой в день в среднем(л)', progress: 2, benchmark:4},
      {name: 'Жирность молока(%)', progress: 0, benchmark:6},
      {name: 'Белки(%)', progress: 0, benchmark:5},
      {name: 'Жир + белки(%)', progress: 0, benchmark:10},
      {name: 'Лактация дней', progress: 125, benchmark: 365},
      {name: 'Вес коз (кг)', progress: 135, benchmark:100},
      {name: 'Приплод (%)', progress: 220, benchmark:300},
  ]

    const galleryImage = [
      {
        src: BurskGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Бурская Порода Коз',
        title: 'Бурская Порода Коз'
      },
      {
        src: BurskGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Бурская Порода Коз',
        title: 'Бурская Порода Коз'
      },
      {
        src: BurskGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Бурская Порода Коз',
        title: 'Бурская Порода Коз'
      },
      {
        src: BurskGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Бурская Порода Коз',
        title: 'Бурская Порода Коз'
      }
    ];
  return (
    <div>
        <SEO title="Бурская порода коз: описание, фото, характеристики"
            description="Бурская порода коз отличается хорошей мускульной массой, так как это одна из немногих пород козы выведенных для производства мяса.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание Бурской породы коз'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
        </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Бурская порода коз</h1>
                    <p className="text-justify">
                    Бурские козы и козлы в продаже в племенном центре Петерсдорф. Порода была выведена в Южной Африке примерно в 1900 году.
                     Название взято от голландского слова бур (boer), что переводится как фермер.
                     Вероятно, бурские козы ведут свое происхождение от местных южно-африканских коз племен Намаква,
                    Сан и Фуку с добавлением крови индийских и европейских пород. Порода создавалась как мясная и в наши дни это,
                    можно сказать, самая популярная мясная порода коз в мире.
                    </p>
                <Row>
                  <Col md="12">
                    <h4>Оглавление:</h4>
                  </Col>
                  <ul className="pl-5">
                    <li><a href="description">Описание породы</a></li>
                    <li><a href="#productivity">Продуктивность</a></li>
                  </ul>
                </Row>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={BurskGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="" 
                                                            title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото коз Бурской породы</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="description">Описание породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <p className="text-justify">Бурские козы обладают высокой устойчивостью к болезням и хорошо адаптируются 
                к сухому и жаркому климату. Традиционный окрас белый с характерной коричневой головой. Некоторые козы могут 
                быть полностью коричневыми или белыми или даже в пятна. Длинные уши придают бурским козам особый шарм и обаяние. 
                Эти козы спокойные, послушные, быстро растут и набирают вес.         
             </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">Голова у альпиек в соотношении с туловищем маленькая, уши прямые, самцы имеют бороду и рога. Холка с выраженным удлинением шерсти, конечности длинные с мощными копытами, длинный хвост.</p>
              <p className="text-justify">Породу любят не только за ее разнообразный,  пестрый окрас, но и за качество и показатели продуктивности молока.</p>
             </Col>
           </Row>
         </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="productivity">Продуктивность коз Бурской породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Плодовитость</h3>
              <p className="text-justify">Как правило, бурские козы многоплодны. Козлят традиционно выращивают на подсосе,
               поэтому бурские козы обладают лучшими материнскими качествами по сравнению с другими породами. 
               Бурские козы набирают вес примерно с той же скоростью, что и их отец, поэтому козел, проверенный на 
               устойчивую передачу признака быстрого набора веса будет стоить намного дороже, чем обычный бурский козел.</p>
             </Col>
             <Col md="6" sm="12">
                <p className="text-justify">Козлята достигают убойного веса 22-36 кг в возрасте отлучения. 
                  В зависимости от генетики разница в весе 90 дневных козлят колоссальная. 
                  Козленок от высококлассного козла может весить 36 кг, в то время как коз козленок от обычного козла только 15 кг. 
                  Покупка дешевого бурского козла для разведения будет привлекательна, но это может значительно подорвать долгосрочную 
                  рентабельность фермы.
                </p>
              </Col>
           </Row>
         </Container>
         <FormQuickOrder className="mt-3" />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default BurskaiaPoroda;